import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./utils/i18n";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import enGB from "date-fns/locale/en-GB";
import pl from "date-fns/locale/pl";

import store from "./store/configureStore";

import App from "./containers/App";
import { Dimmer, Loader } from "semantic-ui-react";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

registerLocale("ru", pl);
registerLocale("en", enGB);

String.prototype.replaceAll = function (search, replacement) {
    const target = this;
    return target.replace(new RegExp(search, "g"), replacement);
};

ReactDOM.render(
    <Provider store={store}>
        <Suspense
            fallback={
                <Dimmer active inverted className="table-loader">
                    <Loader size="huge">Loading</Loader>
                </Dimmer>
            }
        >
            <App />
        </Suspense>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
