import { monthsOptions, yearsOptions } from "../../utils/dateTimeFormater"
import { Icon } from "semantic-ui-react";
import {useTranslation} from 'react-i18next';

export const DateCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => {
    const {t} = useTranslation();
    const getYear = (date) => date.getFullYear();
    const getMonth = (date) => date.getMonth();

    return (
        <div style={styles.container}>
            <button
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                style={{
                ...styles.button,
                ...(prevMonthButtonDisabled && styles.buttonDisabled),
                }}
                aria-label="Previous month"
            >
                <Icon name='left chevron' />
            </button>

            <div style={styles.selectWrapper}>
                <select
                    value={monthsOptions(t)[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                        changeMonth(monthsOptions(t).indexOf(value))
                    }
                    style={styles.select}
                >
                {monthsOptions(t).map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
                </select>

                <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                    style={styles.select}
                >
                {yearsOptions.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
                </select>
            </div>

            <button
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                style={{
                ...styles.button,
                ...(nextMonthButtonDisabled && styles.buttonDisabled),
                }}
                aria-label="Next month"
            >
                <Icon name='right chevron' />
            </button>
        </div>
    )
}

const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      padding: '4px',
    },
    button: {
      padding: '4px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonDisabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    selectWrapper: {
      display: 'flex',
      gap: '8px',
    },
    select: {
      padding: '3px 4px',
      borderRadius: '4px',
      border: '1px solid #e5e7eb',
      fontSize: '14px',
      cursor: 'pointer',
      outline: 'none',
    },
  };