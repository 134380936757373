import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form, Grid, Segment, TextArea } from "semantic-ui-react";
import { SETTINGS_TYPE_EDIT } from "../../../../constants/formTypes";
import { REGISTRATION_TC_GRID } from "../../../../constants/grids";
import { downloadDocumentRequest } from "../../../../ducks/documents";
import { configRegTCSelector } from "../../../../ducks/gridCard";
import {
    personalDataRequest,
    templatePersonalDataSelector,
} from "../../../../ducks/reports";
import { columnsTypesConfigSelector } from "../../../../ducks/representations";
import { commentsToText } from "../../../../utils/comments";
import FormField from "../formField";
import Documents from "../shared/documents";
import VehicleTable from "./vehicleTable";

const Information = ({
    form = {},
    onChange,
    settings = {},
    error,
    updateHistory,
    isRegTC,
}) => {
    const { t } = useTranslation();

    //Removed temporaily, to restore if needed
    // const documents = useSelector((state) =>
    //     documentsTemplatesSelector(state, "Shared")
    // );
    const docTC = [];
    const columnsConfig = useSelector((state) =>
        !isRegTC
            ? columnsTypesConfigSelector(state, REGISTRATION_TC_GRID)
            : configRegTCSelector(state)
    );

    const dispatch = useDispatch();
    const handleDownload = (id) => {
        dispatch(downloadDocumentRequest({ id }));
    };

    useEffect(() => {
        dispatch(personalDataRequest());
    }, []);

    const url = useSelector((state) => templatePersonalDataSelector(state));

    const onChangeHowDidYouHearAboutUs = (e, { name, value }) => {
        onChange(e, { name, value });
        if (!value || !value.find((v) => v.value === "other"))
            onChange(e, { name: "otherSourceAboutUsForm", value: "" });
    };

    const allCommentsHidden = settings["allComments"] === "hidden";
    const lastCommentHidden = settings["lastComment"] === "hidden";
    const bothCommentFieldsVisible = !allCommentsHidden && !lastCommentHidden;

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width="8">
                        <FormField
                            name="email"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                        <FormField
                            name="aboutUsSources"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChangeHowDidYouHearAboutUs}
                        />
                        {form.aboutUsSources &&
                            form.aboutUsSources.find(
                                (v) => v.value === "other"
                            ) && (
                                <FormField
                                    name="otherSourceAboutUsForm"
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />
                            )}
                    </Grid.Column>

                    {bothCommentFieldsVisible && (
                        <Grid.Column width="8">
                            <Form.Field>
                                <FormField
                                    name="lastComment"
                                    label="comments"
                                    placeholder={t("rtcCommentPlaceholder")}
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />

                                <label>{t("rtcCommentsHistory")}</label>

                                <TextArea
                                    className="textarea-disabled-scroll"
                                    disabled
                                    autoHeight
                                    name="comments"
                                    value={commentsToText(form.comments)}
                                />
                            </Form.Field>
                        </Grid.Column>
                    )}

                    {!bothCommentFieldsVisible && !lastCommentHidden && (
                        <Grid.Column width="8">
                            <Form.Field>
                                <FormField
                                    name="lastComment"
                                    label="comments"
                                    placeholder={t("rtcCommentPlaceholder")}
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />
                            </Form.Field>
                        </Grid.Column>
                    )}

                    {!bothCommentFieldsVisible && !allCommentsHidden && (
                        <Grid.Column width="8">
                            <Form.Field>
                                <label>{t("rtcCommentsHistory")}</label>
                                <TextArea
                                    className="textarea-disabled-scroll"
                                    disabled
                                    autoHeight
                                    name="comments"
                                    value={commentsToText(form.comments)}
                                />
                            </Form.Field>
                        </Grid.Column>
                    )}
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <FormField
                                name="companyVerificationStatus"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                            <Segment>
                                <label className="margin-bottom-10">
                                    {t("transportCompany")}
                                </label>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="companyOPF"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyINN"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="companyOGRN"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="bankAccountNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="invoicingCurrency"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="licenseExpirationDate"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="ocpInsuranceExpirationDate"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="ocpInsurancePartExpirationDate"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="insuranceCompanyName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <FormField
                                                name="companyFactAddress"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="companyFactAddressStreet"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyFactAddressPostalCode"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyFactAddressCountry"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="companyBaseLocationCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyBaseLocationStreet"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="companyBaseLocationPostalCode"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="companyBaseLocationCountry"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                {/*   <label className="margin-bottom-10">
                                    {t("managerToWorkWithFM")}
                                </label>
                               <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="managerLastName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="managerFirstName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="managerPatronymic"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="managerPhoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="managerEmail"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid> */}
                                <br />
                                <label className="margin-bottom-10">
                                    {t("generalManager")}
                                </label>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerFirstName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerLastName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerPatronymic"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerPhoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="generalManagerEmail"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className="margin-bottom-10">
                                    {t("audit")}
                                </label>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="auditDateFrom"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="auditDateTo"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className="margin-bottom-10">
                                    {t("policyTerms")}
                                </label>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="insuranceAmount"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="insuranceCurrency"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="percentageInvolvementInDamage"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="paymentDateLimit"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="theft"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="electronics"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="homeAppliances"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="medicine"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="mugging"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <FormField
                            name="veterinaryRegistrationNumber"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                        <FormField
                            name="transportAgreementSignDate"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Grid.Row columns={3}>
                            <Form.Group widths="equal">
                                <FormField
                                    name="directionsForMoscow"
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />
                            </Form.Group>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className="margin-bottom-10">
                                    {t("selfVehicleParks")}
                                </label>
                                <VehicleTable
                                    form={form}
                                    nameInForm={"selfVehicleParks"}
                                    onChange={onChange}
                                    error={error}
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <label className="margin-bottom-10">
                                    {t("rentedVehicleParks")}
                                </label>
                                <VehicleTable
                                    form={form}
                                    nameInForm={"rentedVehicleParks"}
                                    isRented
                                    onChange={onChange}
                                    error={error}
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <FormField
                            name="isFarma"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <FormField
                            name="isDangerous"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>

                {docTC &&
                    docTC.map((d) => (
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <label className="margin-bottom-10">
                                        {d.name}
                                    </label>
                                    <Documents
                                        gridName={REGISTRATION_TC_GRID}
                                        cardId={form.id}
                                        typeId={d.typeId}
                                        isEditPermissions={
                                            settings &&
                                            settings[
                                                "vehicleRegCertificate"
                                            ] === SETTINGS_TYPE_EDIT
                                        }
                                        updateHistory={updateHistory}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    ))}

                <Grid.Row>
                    <Grid.Column>
                        <div className="grid-card-header ui checkbox">
                            <Checkbox
                                name="isAcceptPersonalDataPolicy"
                                onChange={(e, { checked }) =>
                                    onChange(e, {
                                        name: "isAcceptPersonalDataPolicy",
                                        value: checked,
                                    })
                                }
                                checked={form.isAcceptPersonalDataPolicy}
                                disabled={form.state !== "rtcDraft" || !isRegTC}
                            />
                            <div className="ui checkbox">
                                {t("consentTo")}{" "}
                                <a
                                    onClick={() =>
                                        handleDownload(
                                            url.previousGenericReportTemplateId
                                        )
                                    }
                                    className="docs-download_link"
                                >
                                    {t("processingOfPersonalData")}
                                </a>{" "}
                                *
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Information;
