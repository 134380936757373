import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../ducks/login";
import { homePageSelector } from "../ducks/profile";

const UnauthRoute = ({ component: Component, ...rest }) => {
    const isAuth = useSelector((state) => isAuthSelector(state));
    const homePage = useSelector((state) => homePageSelector(state));

    console.log("DUPA");

    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuth ? <Component {...props} /> : <Redirect to={homePage} />
            }
        />
    );
};

export default UnauthRoute;
