import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Dimmer,
    Form,
    Icon,
    Loader,
    Modal,
    Segment
} from "semantic-ui-react";
import './styles.scss'
import YMap from "./map";
import RadioButtons from "../../components/BaseComponents/RadioButtons";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isTransportCompanyUserSelector} from "../../ducks/profile";
import {
    defaultFiltersSelector,
    getDefaultFiltersRequest,
    getShippingsMapDataRequest,
    mapDataSelector,
    progressSelector
} from "../../ducks/maps";
import {Link, useHistory} from "react-router-dom";
import {GRID_CARD_LINK} from "../../router/links";
import {SHIPPINGS_GRID} from "../../constants/grids";
import DateOrPeriod from "../../components/BaseComponents/DateOrPeriod";
import {toast} from "react-toastify";
import LeafletMap from './leafletMap';

export const colors = {
    blue: '#0066ff',
    orange: '#ff5900',
    green: '#1fb622',
    car: "#EE3B2C"
}

const Point = ({txt, color}) => {

    if (color === colors.car) {
        return (
            <div className='b-point'>
                <div className='car-point'>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.5" cy="10.5" r="9.75" fill="white" stroke="#EE3B2C" stroke-width="1.5"/>
                        <path d="M16.887 12.2696H16.5565V10.0371C16.5565 9.77478 16.4512 9.52283 16.2653 9.33696L14.2022 7.2738C14.0163 7.08793 13.7643 6.98261 13.5021 6.98261H12.5913V5.9913C12.5913 5.44402 12.1473 5 11.6 5H4.9913C4.44402 5 4 5.44402 4 5.9913V12.6C4 13.1473 4.44402 13.5913 4.9913 13.5913H5.32174C5.32174 14.6859 6.20978 15.5739 7.30435 15.5739C8.39891 15.5739 9.28696 14.6859 9.28696 13.5913H11.9304C11.9304 14.6859 12.8185 15.5739 13.913 15.5739C15.0076 15.5739 15.8957 14.6859 15.8957 13.5913H16.887C17.0687 13.5913 17.2174 13.4426 17.2174 13.2609V12.6C17.2174 12.4183 17.0687 12.2696 16.887 12.2696ZM7.30435 14.5826C6.75706 14.5826 6.31304 14.1386 6.31304 13.5913C6.31304 13.044 6.75706 12.6 7.30435 12.6C7.85163 12.6 8.29565 13.044 8.29565 13.5913C8.29565 14.1386 7.85163 14.5826 7.30435 14.5826ZM13.913 14.5826C13.3658 14.5826 12.9217 14.1386 12.9217 13.5913C12.9217 13.044 13.3658 12.6 13.913 12.6C14.4603 12.6 14.9043 13.044 14.9043 13.5913C14.9043 14.1386 14.4603 14.5826 13.913 14.5826ZM15.5652 10.287H12.5913V7.97391H13.5021L15.5652 10.0371V10.287Z" fill="#EE3B2C"/>
                    </svg>
                </div>
                <div className='description'>{txt}</div>
            </div>

        )
    }

    return (
        <div className='b-point'>
            <div className='circle-point'
                style={color ? {
                    outline: `2px solid ${color}`,
                    backgroundColor: color
                } : {}}></div>
            <div className='description'>{txt}</div>
        </div>
    )
}

const ShippingsMap = ({open, onClose}) => {
    const history = useHistory();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [form, setForm] = useState({});
    const [shipping, setShipping] = useState(null);
    const [route, setRoute] = useState(null);

    const onChange = (e, {value, name}) => {
        setForm(f => ({
            ...f,
            [name]: value
        }))
    }

    const isTransportCompanyUser = useSelector(state => isTransportCompanyUserSelector(state));
    const mapData = useSelector(state => mapDataSelector(state));
    const loading = useSelector(state => progressSelector(state));
    const defaultFilters = useSelector(state => defaultFiltersSelector(state));

    const refresh = (f) => {
        setRoute(null)
        setShipping(null)
        dispatch(getShippingsMapDataRequest({
            filters: f || form,
            arrayIsEmptyCallback: () => {
                toast.error(t('shippingsNotFound'))
            }
        }))
    }

    useEffect(() => {
        open && dispatch(getDefaultFiltersRequest())
    }, [open])

    useEffect(() => {
        if (open) {
            setForm(defaultFilters)
            refresh(defaultFilters)
        }
    }, [defaultFilters])

    const closeModal = () => {
        setForm({})
        setRoute(null)
        setShipping(null)
        onClose()
    }

    const btnNameByType = (mD) => {
        switch (mD.pointType) {
            case 0:
                return 'pickUpShipping'
            case 1:
                return 'sendPriceOffer';
            case 2:
                return null;
        }
    }

    const pointInfo = (point) => {
        const isDelivery = point.isDelivery;
        return <Form.Field key={point.orderNumber + '_' + point.isDelivery}>
            <div className='margin-bottom-4'>
                <b>{`${!isDelivery ? t('dateUploading') : t('dateUnloading')}: `}</b>{point.date || ''}</div>
            <div className='margin-bottom-4'>
                <b>{`${!isDelivery ? t('uploading') : t('unloading')} ${point.orderNumber}: `}</b>{point.address || ''}
            </div>
        </Form.Field>
    }

    useEffect(() => {
        !form.datePeriod && onChange(null, {
            name: 'showInProgressShippings',
            value: false
        })
    }, [form.datePeriod])

    const [height, setHeight] = useState(0);

    const ro = useRef(new ResizeObserver(el => {
        setHeight(el[0].target.offsetHeight);
    }));

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container);
        else if (ro.current) {
            ro.current.disconnect();
            setHeight(0);
        }
    }, [ro.current]);

    return (
        <Modal
            open={open}
            closeIcon
            onClose={closeModal}
            className='map-modal'
        >
            <Segment vertical>
                <Dimmer active={loading} inverted>
                    <Loader size="huge"/>
                </Dimmer>
                <div className='map-modal__content'>
                    <div className='map-modal__filters'>
                        <div ref={resize}>
                            <Form >
                                <Form.Field>
                                    <label><b>{t('selectUploadDate')}</b></label>
                                    <DateOrPeriod
                                        noLabel
                                        name='datePeriod'
                                        value={form.datePeriod || ''}
                                        onChange={onChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><b>{t('selectVehicleType')}</b></label>
                                    <RadioButtons
                                        isMultiSelect
                                        noLabel
                                        source='vehicleTypeEnum'
                                        name='vehicleTypes'
                                        value={form.vehicleTypes}
                                        onChange={onChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><b>{t('selectVehicleBodyType')}</b></label>
                                    <RadioButtons
                                        isMultiSelect
                                        noLabel
                                        source='bodyTypes'
                                        name='vehicleBodyTypes'
                                        value={form.vehicleBodyTypes}
                                        onChange={onChange}
                                    />
                                </Form.Field>
                                {form.datePeriod && <Form.Field>
                                    <Checkbox
                                        label={t(isTransportCompanyUser ? 'showMyShippings' : 'showTcShippings')}
                                        onChange={(e, {checked}) => onChange(e, {
                                            name: 'showInProgressShippings',
                                            value: checked
                                        })}
                                        checked={form.showInProgressShippings}
                                    />
                                    <span className='field-hint'>* {t('shippingMap.periodInfo')}</span>
                                </Form.Field>}
                                <Button
                                    className='map-modal__btn'
                                    color='blue'
                                    onClick={() => refresh()}
                                >
                                    <b>{t('refreshShippings')}</b>
                                </Button>
                                <div className='map-modal__legend'>
                                    <label><b>{t('shippingMap.colorSchemeInfo')}:</b></label>
                                    <div className='map-modal__legend-points'>
                                        <Point color={colors.blue} txt={t('shippingMap.legend.firstUploadingPoint')}/>
                                        <Point txt={t('shippingMap.legend.intermediatePoint')}/>
                                        <Point color={colors.green} txt={t('shippingMap.legend.lastUnloadingPoint')}/>
                                        <Point color={colors.orange} txt={t('shippingMap.legend.lastInprogressPointUnloading')}/>
                                        <Point color={colors.car} txt={t('shippingMap.legend.vehicle')}/>
                                    </div>
                                </div>
                            </Form>
                            <div className='map-modal__line'/>
                        </div>

                        {shipping && <Form className='map-modal__scroll' style={{height: `calc(100% - ${height}px`}}>
                            <Form.Field className='d-flex'>
                                <b className='map-modal__shipping-number'>
                                    <Link
                                        className='color__black'
                                        to={GRID_CARD_LINK.replace(':name', SHIPPINGS_GRID).replace(':id', shipping.id)}
                                    >
                                        №{shipping.shippingNumber}
                                    </Link>
                                    {shipping.pointType !== 2 && shipping.cost ? ` - ${shipping.cost} ₽` : ''}
                                </b>
                                {shipping.pointType !== 2 &&
                                    <Button
                                        className='margin-left-8 w-100'
                                        size='mini'
                                        onClick={() => setRoute(route => (route && shipping.id === route.id) ? null : shipping)}
                                    >
                                        <Icon name='map marker alternate'/> <b>{t('route')}</b>
                                    </Button>
                                }
                            </Form.Field>
                            {
                                (shipping.stoppers || []).map(s => pointInfo(s))
                            }
                            <Form.Field>
                                <div className='margin-bottom-4'><b>{shipping.vehicleTypeName}</b></div>
                                <div className='margin-bottom-4'>
                                    <b>{`${t('contactNumber')}: ${shipping.contactNumber || ''}`}</b></div>
                            </Form.Field>
                            {btnNameByType(shipping) && <Button
                                className='map-modal__btn'
                                color='blue'
                                onClick={() => history.push(GRID_CARD_LINK.replace(':name', SHIPPINGS_GRID).replace(':id', shipping.id))}
                            >
                                <b>{t(btnNameByType(shipping))}</b>
                            </Button>}
                        </Form>}
                    </div>
                    <div className='map-modal__map'>
                        <LeafletMap 
                            isTransportCompanyUser={isTransportCompanyUser}
                            mapData={mapData}
                            setShipping={setShipping}
                            routeData={route}
                            setRouteData={setRoute} 
                        />
                        {/* <YMap
                            isTransportCompanyUser={isTransportCompanyUser}
                            mapData={mapData}
                            setShipping={setShipping}
                            routeData={route}
                            setRouteData={setRoute}
                        /> */}
                    </div>
                </div>
            </Segment>
        </Modal>
    );
};

export default ShippingsMap;