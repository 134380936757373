import React from "react";
import { Menu, Popup, Icon as IconSUI } from "semantic-ui-react";
import { PERSONAL_CABINET_LINK, REPORT_LINK } from "../../router/links";
import {
    PERSONAL_CABINET_ADDITIONAL_INFORMATION,
    PERSONAL_CABINET_AVAILABLE_PARK,
    PERSONAL_CABINET_CONTACTS_FM,
    PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS,
    PERSONAL_CABINET_KPI,
    PERSONAL_CABINET_LATENESS,
    PERSONAL_CABINET_MEETING_PROTOCOLS,
    PERSONAL_CABINET_TURNOVER,
} from "../../constants/personalCabinetPages";
import { useTranslation } from "react-i18next";
import { DOC_RETURN_REPORT_TYPE } from "../../constants/reportType";
import { useSelector } from "react-redux";
import { gridPermissionSelector, pcPermissions } from "../../ducks/profile";
import Icon from "../../components/CustomIcon";
import qs from "qs";
import { useHistory } from "react-router-dom";

const PersonalCabinetMenu = ({ activeItem, params }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const goTo = (name) => {
        history.push(PERSONAL_CABINET_LINK.replace(":pageName", name));
    };

    const latesPermission = useSelector((state) =>
        gridPermissionSelector(state, PERSONAL_CABINET_LATENESS)
    );
    const meetingProtocolsPermission = useSelector((state) =>
        gridPermissionSelector(state, PERSONAL_CABINET_MEETING_PROTOCOLS)
    );
    const documentsAndInstructionsPermission = useSelector((state) =>
        gridPermissionSelector(
            state,
            PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS
        )
    );
    const isTurnoverAvaliable = useSelector(pcPermissions).isTurnoverAvaliable;

    return (
        <Menu icon vertical className="personal-cabinet__menu" inverted>
            <Popup
                className="personal-cabinet__popup"
                content={t("personalCabinetHome")}
                position="right center"
                trigger={
                    <Menu.Item
                        name="home"
                        active={activeItem === "home"}
                        onClick={() => goTo("home")}
                    >
                        <Icon name="menu" color="white" size={24} />
                    </Menu.Item>
                }
            />
            <Popup
                className="personal-cabinet__popup"
                content={t("serviceTypeKpi")}
                position="right center"
                trigger={
                    <Menu.Item
                        name={PERSONAL_CABINET_KPI}
                        active={activeItem === PERSONAL_CABINET_KPI}
                        onClick={() => goTo(PERSONAL_CABINET_KPI)}
                    >
                        <Icon name="stat-monitor" color="white" size={24} />
                    </Menu.Item>
                }
            />
            <Popup
                className="personal-cabinet__popup"
                content={t("personalCabinetDocuments")}
                position="right center"
                trigger={
                    <Menu.Item
                        name={DOC_RETURN_REPORT_TYPE}
                        active={activeItem === DOC_RETURN_REPORT_TYPE}
                        onClick={() =>
                            history.push({
                                pathname: REPORT_LINK.replace(
                                    ":type",
                                    DOC_RETURN_REPORT_TYPE
                                ),
                                search: qs.stringify({
                                    startDate: params.startDate,
                                    endDate: params.endDate,
                                    company: params.company,
                                }),
                            })
                        }
                    >
                        <Icon name="return-state" color="white" size={24} />
                    </Menu.Item>
                }
            />
            {latesPermission && (
                <Popup
                    className="personal-cabinet__popup"
                    content={t("lates")}
                    position="right center"
                    trigger={
                        <Menu.Item
                            name={PERSONAL_CABINET_LATENESS}
                            active={activeItem === PERSONAL_CABINET_LATENESS}
                            onClick={() => goTo(PERSONAL_CABINET_LATENESS)}
                        >
                            <Icon name="late" color="white" size={24} />
                        </Menu.Item>
                    }
                />
            )}
            {isTurnoverAvaliable && (
                <Popup
                    className="personal-cabinet__popup"
                    content={t("turnover")}
                    position="right center"
                    trigger={
                        <Menu.Item
                            name={PERSONAL_CABINET_TURNOVER}
                            active={activeItem === PERSONAL_CABINET_TURNOVER}
                            onClick={() => goTo(PERSONAL_CABINET_TURNOVER)}
                        >
                            <Icon
                                name="info-turnover"
                                color="white"
                                size={24}
                            />
                        </Menu.Item>
                    }
                />
            )}
            {meetingProtocolsPermission && (
                <Popup
                    className="personal-cabinet__popup"
                    content={t(PERSONAL_CABINET_MEETING_PROTOCOLS)}
                    position="right center"
                    trigger={
                        <Menu.Item
                            name={PERSONAL_CABINET_MEETING_PROTOCOLS}
                            active={
                                activeItem ===
                                PERSONAL_CABINET_MEETING_PROTOCOLS
                            }
                            onClick={() =>
                                goTo(PERSONAL_CABINET_MEETING_PROTOCOLS)
                            }
                        >
                            <Icon name="protocol" color="white" size={24} />
                        </Menu.Item>
                    }
                />
            )}
            <Popup
                className="personal-cabinet__popup"
                content={t("fMContacts")}
                position="right center"
                trigger={
                    <Menu.Item
                        name={PERSONAL_CABINET_CONTACTS_FM}
                        active={activeItem === PERSONAL_CABINET_CONTACTS_FM}
                        onClick={() => goTo(PERSONAL_CABINET_CONTACTS_FM)}
                    >
                        <Icon name="contacts" color="white" size={24} />
                    </Menu.Item>
                }
            />
            <Popup
                className="personal-cabinet__popup"
                content={t(PERSONAL_CABINET_AVAILABLE_PARK)}
                position="right center"
                trigger={
                    <Menu.Item
                        name={PERSONAL_CABINET_AVAILABLE_PARK}
                        active={activeItem === PERSONAL_CABINET_AVAILABLE_PARK}
                        onClick={() => goTo(PERSONAL_CABINET_AVAILABLE_PARK)}
                    >
                        <Icon name="vehicle" color="white" size={24} />
                    </Menu.Item>
                }
            />
            <Popup
                className="personal-cabinet__popup"
                content={t(PERSONAL_CABINET_ADDITIONAL_INFORMATION)}
                position="right center"
                trigger={
                    <Menu.Item
                        name={PERSONAL_CABINET_ADDITIONAL_INFORMATION}
                        active={
                            activeItem ===
                            PERSONAL_CABINET_ADDITIONAL_INFORMATION
                        }
                        onClick={() =>
                            goTo(PERSONAL_CABINET_ADDITIONAL_INFORMATION)
                        }
                    >
                        <Icon name="info" color="white" size={24} />
                    </Menu.Item>
                }
            />
            {documentsAndInstructionsPermission && (
                <Popup
                    className="personal-cabinet__popup"
                    content={t(PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS)}
                    position="right center"
                    trigger={
                        <Menu.Item
                            name={PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS}
                            active={
                                activeItem ===
                                PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS
                            }
                            onClick={() =>
                                goTo(
                                    PERSONAL_CABINET_DOCUMENTS_AND_INSTRUCTIONS
                                )
                            }
                        >
                            <IconSUI
                                className="menu-icon-sui"
                                name="file alternate"
                                color="white"
                                size="large"
                            />
                        </Menu.Item>
                    }
                />
            )}
        </Menu>
    );
};

export default PersonalCabinetMenu;
