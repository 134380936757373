import { getYear } from 'date-fns';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import moment from 'moment';

export const parseDate = dateString => {
    if (!dateString) return null;
    try {
        const parts = dateString.split('.');
        let d = new Date(parts[2], parts[1] - 1, parts[0]);
        return isNaN(d.getTime()) ? null : d;
    } catch (e) {
        return null;
    }
};

export const parseDateTime = dateString => {
    if (!dateString) return null;
    let reg = /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2})/;
    let dateArray = reg.exec(dateString);
    if (!dateArray) return null;
    let d = new Date(+dateArray[3], +dateArray[2] - 1, +dateArray[1], +dateArray[4], +dateArray[5]);
    return isNaN(d.getTime()) ? null : d;
};

export const parseUtcDateTimeToMilliseconds = dateString => {
    const result = parseUtcDateTimeToDate(dateString);
    if (!result) return null;

    return result;
};

export const parseUtcDateTimeToDate = dateString => {
    if (!dateString) return null;
    const stillUtc = moment.utc(dateString).toDate();
    const local = moment(stillUtc)
        .local();

    return new Date(local);
};

export const dateToString = (date = new Date(), dateFormat = 'dd.MM.yyyy') => {
    return format(date, dateFormat);
};

export const formatDate = (date, dateFormat = 'dd.MM.yyyy') => {
    if (!date) return null;

    return format(date, dateFormat);
};

export const formatUtcDate = (date) => {
    if (!date) return null;

    return moment.utc(date)
};

export const dateToUTC = (date, format = 'DD.MM.YYYY', isNotUtc) => {
    if (!date) {
        return null;
    }

    if (isNotUtc) {
        return moment(date).format(format);
    }

    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc)
        .local()
        .format(format);

    return local;
};

export const isRange = (dateStart, dateEnd, date) => {
    return isWithinInterval(parseDate(date), {
        start: parseDate(dateStart),
        end: dateEnd ? parseDate(dateEnd) : new Date(),
    });
};

export const utcToLocal = (value) => {
    let testDateUtc = moment.utc(value, "DD.MM.YYYY HH:mm");
    return moment(testDateUtc).local();
}

export const range = (start, end, step = 1) => {
    return Array.from(
        { length: Math.floor((end - start) / step) + 1 },
        (_, index) => start + (index * step)
    );
}

export const yearsOptions = range(1970, getYear(new Date()) + 10, 1);

export const monthsOptions = (t) => [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
];