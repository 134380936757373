import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, Icon, Segment } from "semantic-ui-react";
import FormField from "../formField";
import { useDispatch, useSelector } from "react-redux";
import { columnsTypesConfigSelector } from "../../../../ducks/representations";
import { SHIPPINGS_GRID } from "../../../../constants/grids";
import {
    DICTIONARY_CARD_LINK,
    DICTIONARY_NEW_LINK,
} from "../../../../router/links";
import {
    SETTINGS_TYPE_HIDE,
    SETTINGS_TYPE_SHOW,
} from "../../../../constants/formTypes";
import { APPROVED } from "../../../../constants/securityServiceStates";
import { ATTRACTED, LOAN } from "../../../../constants/ownershipTypes";
import DriverInnModal from "./driverInnModal";
import {
    checkDriver,
    checkFieldsSelector,
    checkInnDriverSelector,
    checkLicenseDriverSelector,
    checkModelResultSelector,
    checkRequiredFields,
    isExistModel,
    setCheckDriver,
    setCheckRequiredFields,
    setIsExistModel,
    setRequiredFieldsFromModal,
} from "../../../../ducks/gridCard";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import { ENUM_TYPE } from "../../../../constants/columnTypes";
import DriverLicenseModal from "./driverLicenseModal";
import ModelModal from "./modelModal";

const Information = ({ form = {}, onChange, settings = {}, error }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const columnsConfig =
        useSelector((state) =>
            columnsTypesConfigSelector(state, SHIPPINGS_GRID)
        ) || {};
    const checkInnDriver = useSelector((state) =>
        checkInnDriverSelector(state)
    );
    const checkLicenseDriver = useSelector((state) =>
        checkLicenseDriverSelector(state)
    );
    const checkFields = useSelector((state) => checkFieldsSelector(state));
    const checkModelVehicleResult = useSelector((state) =>
        checkModelResultSelector(state, "vehicles")
    );
    const checkModelTrailerResult = useSelector((state) =>
        checkModelResultSelector(state, "trailers")
    );

    const [openDriverInnModal, setOpenDriverInnModal] = useState(false);
    const [openDriverLicenseModal, setOpenDriverLicenseModal] = useState(false);
    const [openVehicleModelModal, setOpenVehicleModelModal] = useState(false);
    const [openTrailerModelModal, setOpenTrailerModelModal] = useState(false);
    const [openFieldsModal, setOpenFieldsModal] = useState(false);

    const transportCompanyVehicleOwnershipType =
        "transportCompanyVehicleOwnershipType";
    const transportCompanyTrailerOwnershipType =
        "transportCompanyTrailerOwnershipType";
    const trailerId = "trailerId";
    const vehicleId = "vehicleId";

    useEffect(() => {
        return () => {
            dispatch(setCheckRequiredFields(true));
            dispatch(setIsExistModel({ vehicles: true }));
            dispatch(setIsExistModel({ trailers: true }));
        };
    }, []);

    const saveRequiredFields = (data) => {
        dispatch(setRequiredFieldsFromModal(data));
    };

    const formObjectIsEmpty = (name) =>
        !form[name] || Object.keys(form[name]).length === 0;
    const vehicleOwnershipIsAttracted = () =>
        !formObjectIsEmpty(vehicleId) &&
        form[transportCompanyVehicleOwnershipType] &&
        [ATTRACTED, LOAN].includes(form[transportCompanyVehicleOwnershipType]);
    const getTrailerOwnershipTypeIs = (name) =>
        !formObjectIsEmpty(trailerId) &&
        form[transportCompanyTrailerOwnershipType] &&
        form[transportCompanyTrailerOwnershipType] === name;

    const disabledItems = {
        [transportCompanyTrailerOwnershipType]: {
            loan:
                getTrailerOwnershipTypeIs(ATTRACTED) ||
                !getTrailerOwnershipTypeIs(LOAN),
            leasing:
                getTrailerOwnershipTypeIs(ATTRACTED) ||
                getTrailerOwnershipTypeIs(LOAN),
            ownership:
                getTrailerOwnershipTypeIs(ATTRACTED) ||
                getTrailerOwnershipTypeIs(LOAN),
            attracted:
                !getTrailerOwnershipTypeIs(ATTRACTED) ||
                getTrailerOwnershipTypeIs(LOAN),
        },
    };

    const phoneNumberBindingType = {
        bindByDriver: 0,
        bindByVehicle: 1,
    };

    const handleChangeOwnershipType = useCallback(
        (e, { name, value }) => {
            onChange(e, {
                name,
                value,
            });

            if (value && name === vehicleId) {
                dispatch(checkRequiredFields(value.value));
                dispatch(
                    isExistModel({
                        name: "vehicles",
                        form: { [vehicleId]: value.value },
                    })
                );
            }

            if (value && name === trailerId) {
                dispatch(
                    isExistModel({
                        name: "trailers",
                        form: { [trailerId]: value.value },
                    })
                );
            }

            if (
                name === transportCompanyVehicleOwnershipType &&
                [ATTRACTED, LOAN].includes(value)
            ) {
                onChange(e, {
                    name: transportCompanyTrailerOwnershipType,
                    value: value,
                });
            }
            if (
                name === transportCompanyVehicleOwnershipType &&
                ![ATTRACTED, LOAN].includes(value) &&
                [ATTRACTED, LOAN].includes(
                    form[transportCompanyVehicleOwnershipType]
                )
            ) {
                onChange(e, {
                    name: transportCompanyTrailerOwnershipType,
                    value: form[trailerId]
                        ? form[trailerId]["transportCompanyOwnershipType"]
                        : null,
                });
            }
            if (name === vehicleId && !value) {
                onChange(e, {
                    name: transportCompanyVehicleOwnershipType,
                    value: null,
                });
                onChange(e, {
                    name: "carrierVehicleOwnershipType",
                    value: null,
                });
            }
            if (name === vehicleId && value) {
                //автозаполнение из ТС
                onChange(e, {
                    name: transportCompanyVehicleOwnershipType,
                    value: value["transportCompanyOwnershipType"],
                });
                onChange(e, {
                    name: "carrierName",
                    value: value["carrier"],
                });
                onChange(e, {
                    name: "inn",
                    value: value["carrierInn"],
                });
                onChange(e, {
                    name: "legalAddress",
                    value: value["carrierLegalAddress"],
                });
                onChange(e, {
                    name: "telephoneNumber",
                    value: value["carrierTelephoneNumber"],
                });
                onChange(e, {
                    name: "carrierVehicleOwnershipType",
                    value: value["carrierOwnershipType"],
                });
                if (
                    value["driverTelephoneNumber"] &&
                    value["phoneNumberBindingType"] ===
                        phoneNumberBindingType.bindByVehicle
                ) {
                    //номер телефона водителя из ТС
                    onChange(e, {
                        name: "driverTelephoneNumber",
                        value: value["driverTelephoneNumber"],
                    });
                } else if (
                    form.editableDriverId &&
                    form.editableDriverId["driverTelephoneNumber"]
                ) {
                    onChange(e, {
                        name: "driverTelephoneNumber",
                        value: form.editableDriverId["driverTelephoneNumber"],
                    });
                }
            }
            if (name === trailerId && value) {
                onChange(e, {
                    name: "carrierTrailerOwnershipType",
                    value: value["carrierOwnershipType"],
                });
                onChange(e, {
                    name: transportCompanyTrailerOwnershipType,
                    value: ![ATTRACTED, LOAN].includes(
                        form[transportCompanyVehicleOwnershipType]
                    )
                        ? value["transportCompanyOwnershipType"]
                        : form[transportCompanyVehicleOwnershipType],
                });
            }
            if (name === trailerId && !value) {
                onChange(e, {
                    name: transportCompanyTrailerOwnershipType,
                    value: null,
                });
                onChange(e, {
                    name: "carrierTrailerOwnershipType",
                    value: null,
                });
            }
        },
        [form]
    );

    const handleChangeInn = useCallback((e, { value }) => {
        if (value) {
            onChange(e, {
                name: "inn",
                value: value["carrierInn"],
            });
            onChange(e, {
                name: "carrierName",
                value: value["carrier"],
            });
            onChange(e, {
                name: "legalAddress",
                value: value["carrierLegalAddress"],
            });
            onChange(e, {
                name: "address",
                value: value["carrierAddress"] ? value["carrierAddress"] : null,
            });
            onChange(e, {
                name: "cityName",
                value: value["carrierCityName"]
                    ? value["carrierCityName"]
                    : null,
            });
            onChange(e, {
                name: "postalCode",
                value: value["carrierPostalCode"]
                    ? value["carrierPostalCode"]
                    : null,
            });
            onChange(e, {
                name: "telephoneNumber",
                value: value["carrierTelephoneNumber"]
                    ? value["carrierTelephoneNumber"]
                    : "",
            });
        }
    }, []);

    const autocompleteItemsVehicle = [
        {
            name: "inn",
            nameValue: "carrierInn",
        },
        {
            name: "carrierName",
            nameValue: "carrier",
        },
        {
            name: "legalAddress",
            nameValue: "carrierLegalAddress",
        },
        {
            name: "address",
            nameValue: "carrierAddress",
        },
        {
            name: "cityName",
            nameValue: "carrierCityName",
        },
        {
            name: "postalCode",
            nameValue: "carrierPostalCode",
        },
        {
            name: "telephoneNumber",
            nameValue: "carrierTelephoneNumber",
        },
        {
            name: "carrierVehicleOwnershipType",
            nameValue: "carrierOwnershipType",
        },
        {
            name: transportCompanyVehicleOwnershipType,
            nameValue: "transportCompanyOwnershipType",
            dependentValueName: transportCompanyTrailerOwnershipType,
            dependentValue: ATTRACTED,
        },
        {
            name: transportCompanyVehicleOwnershipType,
            nameValue: "transportCompanyOwnershipType",
            dependentValueName: transportCompanyTrailerOwnershipType,
            dependentValue: LOAN,
        },
    ];

    const autocompleteItemsTrailer = [
        {
            name: transportCompanyTrailerOwnershipType,
            nameValue: "transportCompanyOwnershipType",
        },
        {
            name: "carrierTrailerOwnershipType",
            nameValue: "carrierOwnershipType",
        },
    ];

    const [carrierIsTiedVehicle] = useState(!!form["inn"]); //привязка к данному ТС
    const [carrierIsTiedTrailer] = useState(
        !!form[transportCompanyTrailerOwnershipType]
    ); //привязка к данному прицепу

    const disabledInput = (column) => {
        switch (column) {
            case transportCompanyVehicleOwnershipType:
                //задизейблено, если не выбрано ТС
                return formObjectIsEmpty(vehicleId);
            case transportCompanyTrailerOwnershipType:
                //задизейблено, если не выбран прицеп
                return formObjectIsEmpty(trailerId);
            case "carrierName":
            case "legalAddress":
            case "telephoneNumber":
            case "carrierVehicleOwnershipType":
                return (
                    !form["inn"] && //задизейблено, если пользователь не выбрал перевозчика в поле ИНН
                    !carrierIsTiedVehicle
                ); //и если указанный перевозчик не привязан к данному ТС для данной ТК
            // и если пользователь не нажал в поле ИНН ссылку “добавить перевозчика”
            case "carrierTrailerOwnershipType":
                return (
                    !form["inn"] && //задизейблено, если пользователь не выбрал перевозчика в поле ИНН
                    !carrierIsTiedTrailer
                ); //и если указанный перевозчик не привязан к данному прицепу для данной ТК
            // и если пользователь не нажал в поле ИНН ссылку “добавить перевозчика”
            default:
                return false;
        }
    };

    const innAtiCodeDisabled = () => {
        return (
            form[vehicleId] &&
            form[vehicleId].securityServiceCheckState === APPROVED &&
            (!form[trailerId] ||
                (form[trailerId] &&
                    form[trailerId].securityServiceCheckState === APPROVED)) &&
            form["editableDriverId"] &&
            form["editableDriverId"].securityServiceCheckState === APPROVED
        );
    };

    useEffect(() => {
        setOpenDriverInnModal(!checkInnDriver);
    }, [checkInnDriver]);

    useEffect(() => {
        setOpenDriverLicenseModal(!checkLicenseDriver);
    }, [checkLicenseDriver]);

    useEffect(() => {
        setOpenFieldsModal(!checkFields);
    }, [checkFields]);

    useEffect(() => {
        setOpenVehicleModelModal(!checkModelVehicleResult);
    }, [checkModelVehicleResult]);

    useEffect(() => {
        setOpenTrailerModelModal(!checkModelTrailerResult);
    }, [checkModelTrailerResult]);

    const setDriver = (e, { value, name }) => {
        onChange(e, {
            name,
            value,
        });

        if (
            (form[vehicleId] && !form[vehicleId]["driverTelephoneNumber"]) || //если в текущем выбранном ТС нет номера телефона водителя, то номер берем из данных водителя
            (value &&
                value["phoneNumberBindingType"] ===
                    phoneNumberBindingType.bindByDriver)
        ) {
            onChange(e, {
                name: "driverTelephoneNumber",
                value: value ? value.driverTelephoneNumber : "",
            });
        }
        value && dispatch(checkDriver(value.value));
    };

    useEffect(() => {
        //для проверки ИНН и ВУ при добавлении водителя через соответствующую кнопку
        if (
            location.state &&
            location.state.lookup &&
            location.state.name === "editableDriverId"
        ) {
            dispatch(checkDriver(location.state.lookup.value));
            window.history.replaceState(null, "");
        }
        //для проверки полей при добавлении ТС через соответствующую кнопку
        if (
            location.state &&
            location.state.lookup &&
            location.state.name === vehicleId
        ) {
            dispatch(checkRequiredFields(location.state.lookup.value));
            window.history.replaceState(null, "");
        }
    }, [location.state]);

    const linkToCardBtn = (dictionaryName, val) => {
        const onClick = () => {
            window.open(
                DICTIONARY_CARD_LINK.replace(":name", dictionaryName).replace(
                    ":id",
                    val.value
                ),
                "_blank"
            );
        };

        return (
            <Button
                className="margin-right-10"
                disabled={!val}
                icon="share"
                onClick={onClick}
            />
        );
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width="6">
                        <FormField
                            name="transportCompanyId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width="4">
                        <FormField
                            name="transportCompanyServiceTypeId"
                            isTranslate
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width="2">
                        <FormField
                            name="tarifficationType"
                            text="shippingTarifficationType"
                            isTranslate
                            isReadOnly
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width="4">
                        <FormField
                            name="shippingNumber"
                            isTranslate
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("vehicleInfoGroup")}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column width="5">
                                            <FormField
                                                isReadOnly
                                                name="vehicleTypeId"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width="4">
                                            <label
                                                style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                }}
                                            >
                                                {t("temperature")}
                                            </label>
                                            <div className="temperature-fields">
                                                <label>{t("from")}</label>
                                                <FormField
                                                    noLabel
                                                    isReadOnly
                                                    name="vehicleTypeTemperatureMin"
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                />
                                                <label>{t("to")}</label>
                                                <FormField
                                                    noLabel
                                                    isReadOnly
                                                    name="vehicleTypeTemperatureMax"
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width="3">
                                            <FormField
                                                name="vehicleTypeCapacity"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="vehicleTypeAdditionalCondition"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>

                                        {/* <Grid.Column width="5">
                                            <FormField
                                                name="vehiclePaymentDate"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column> */}
                                    </Grid.Row>
                                    <Grid.Row columns={1}>
                                        <Grid.Column width="16">
                                            <FormField
                                                name="addTempControl"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                                rows="1"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Segment>
                                <Grid>
                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={14}>
                                            <FormField
                                                name={vehicleId}
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={
                                                    handleChangeOwnershipType
                                                }
                                            />
                                        </Grid.Column>

                                        <Grid.Column
                                            width={2}
                                            textAlign="right"
                                        >
                                            <div className="d-flex">
                                                {linkToCardBtn(
                                                    "vehicles",
                                                    form[vehicleId]
                                                )}
                                                <Button
                                                    icon
                                                    disabled={
                                                        settings[vehicleId] ===
                                                            SETTINGS_TYPE_SHOW ||
                                                        settings[vehicleId] ===
                                                            SETTINGS_TYPE_HIDE ||
                                                        columnsConfig[vehicleId]
                                                            .isReadOnly
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname:
                                                                DICTIONARY_NEW_LINK.replace(
                                                                    ":name",
                                                                    "vehicles"
                                                                ),
                                                            state: {
                                                                pathname:
                                                                    location.pathname,
                                                                form,
                                                                autocompleteItems:
                                                                    autocompleteItemsVehicle,
                                                                name: vehicleId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Icon name="add"></Icon>
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={16}>
                                            <FormField
                                                name={
                                                    transportCompanyVehicleOwnershipType
                                                }
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                isDisabled={disabledInput(
                                                    transportCompanyVehicleOwnershipType
                                                )}
                                                form={form}
                                                error={error}
                                                isRequired
                                                onChange={
                                                    handleChangeOwnershipType
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Column width="5">
                                        <FormField
                                            name="vehiclePaymentDate"
                                            isReadOnly
                                            columnsConfig={columnsConfig}
                                            settings={settings}
                                            form={form}
                                            error={error}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={14}>
                                            <FormField
                                                name={trailerId}
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={
                                                    handleChangeOwnershipType
                                                }
                                            />
                                        </Grid.Column>
                                        <Grid.Column
                                            width={2}
                                            textAlign="right"
                                        >
                                            <div className="d-flex">
                                                {linkToCardBtn(
                                                    "trailers",
                                                    form[trailerId]
                                                )}
                                                <Button
                                                    icon
                                                    disabled={
                                                        settings[trailerId] ===
                                                            SETTINGS_TYPE_SHOW ||
                                                        settings[trailerId] ===
                                                            SETTINGS_TYPE_HIDE ||
                                                        columnsConfig[trailerId]
                                                            .isReadOnly
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname:
                                                                DICTIONARY_NEW_LINK.replace(
                                                                    ":name",
                                                                    "trailers"
                                                                ),
                                                            state: {
                                                                pathname:
                                                                    location.pathname,
                                                                form,
                                                                autocompleteItems:
                                                                    form[
                                                                        transportCompanyVehicleOwnershipType
                                                                    ] !==
                                                                    ATTRACTED
                                                                        ? autocompleteItemsTrailer
                                                                        : null,
                                                                name: trailerId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Icon name="add"></Icon>
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={16}>
                                            <FormField
                                                name={
                                                    transportCompanyTrailerOwnershipType
                                                }
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                isDisabled={
                                                    disabledInput(
                                                        transportCompanyTrailerOwnershipType
                                                    ) ||
                                                    disabledItems[
                                                        transportCompanyTrailerOwnershipType
                                                    ]
                                                }
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                                isRequired
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    {vehicleOwnershipIsAttracted() && (
                                        <Grid.Row textAlign="bottom">
                                            <Grid.Column width={3}>
                                                <FormField
                                                    name="inn"
                                                    onClickInnInput={onChange}
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={handleChangeInn}
                                                    isRequired
                                                />
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <FormField
                                                    name="carrierName"
                                                    isDisabled={disabledInput(
                                                        "carrierName"
                                                    )}
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                    isRequired
                                                />
                                            </Grid.Column>

                                            <Grid.Column width={7}>
                                                <FormField
                                                    name="legalAddress"
                                                    rows={1}
                                                    isDisabled={disabledInput(
                                                        "legalAddress"
                                                    )}
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                    isRequired
                                                />
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <FormField
                                                    name="telephoneNumber"
                                                    isDisabled={disabledInput(
                                                        "telephoneNumber"
                                                    )}
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                    isRequired
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}

                                    {vehicleOwnershipIsAttracted() && (
                                        <Grid.Row textAlign="bottom">
                                            <Grid.Column width={16}>
                                                <FormField
                                                    name="carrierVehicleOwnershipType"
                                                    isDisabled={disabledInput(
                                                        "carrierVehicleOwnershipType"
                                                    )}
                                                    columnsConfig={
                                                        columnsConfig
                                                    }
                                                    settings={settings}
                                                    form={form}
                                                    error={error}
                                                    onChange={onChange}
                                                    isRequired
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}

                                    {vehicleOwnershipIsAttracted() &&
                                        form[trailerId] && (
                                            <Grid.Row textAlign="bottom">
                                                <Grid.Column width={16}>
                                                    <FormField
                                                        name="carrierTrailerOwnershipType"
                                                        isDisabled={disabledInput(
                                                            "carrierTrailerOwnershipType"
                                                        )}
                                                        columnsConfig={
                                                            columnsConfig
                                                        }
                                                        settings={settings}
                                                        form={form}
                                                        error={error}
                                                        onChange={onChange}
                                                        isRequired
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}

                                    <Grid.Row textAlign="bottom">
                                        <Grid.Column width={9}>
                                            <FormField
                                                name="editableDriverId"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={setDriver}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <FormField
                                                isDisabled={
                                                    !form["editableDriverId"]
                                                }
                                                name="driverTelephoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                                warningText={"check_number"}
                                            />
                                        </Grid.Column>
                                        <Grid.Column
                                            width={2}
                                            textAlign="right"
                                        >
                                            <div className="d-flex">
                                                {linkToCardBtn(
                                                    "transportCompanyDrivers",
                                                    form["editableDriverId"]
                                                )}
                                                <Button
                                                    icon
                                                    disabled={
                                                        settings[
                                                            "editableDriverId"
                                                        ] ===
                                                            SETTINGS_TYPE_SHOW ||
                                                        settings[
                                                            "editableDriverId"
                                                        ] ===
                                                            SETTINGS_TYPE_HIDE ||
                                                        columnsConfig[
                                                            "editableDriverId"
                                                        ].isReadOnly
                                                    }
                                                    onClick={() => {
                                                        history.push({
                                                            pathname:
                                                                DICTIONARY_NEW_LINK.replace(
                                                                    ":name",
                                                                    "transportCompanyDrivers"
                                                                ),
                                                            state: {
                                                                pathname:
                                                                    location.pathname,
                                                                autocompleteItems:
                                                                    [
                                                                        {
                                                                            name: "driverTelephoneNumber",
                                                                            nameValue:
                                                                                "driverTelephoneNumber",
                                                                        },
                                                                    ],
                                                                form,
                                                                name: "editableDriverId",
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Icon name="add"></Icon>
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                name="innAttractedTransport"
                                                displayNameKey="commentForTheSecurityService"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                isDisabled={innAtiCodeDisabled()}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("commonCargoInfo")}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column width="6">
                                            <FormField
                                                name="shippingCargoUnits"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="6">
                                            <FormField
                                                name="productTypes"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="weightKg"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                            <FormField
                                                name="cargoCost"
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("commonRouteInfo")}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingClientName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingClientName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingAddress1"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingAddress1"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingAddress2And3"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingAddress2And3"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingPostalCode"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingPostalCode"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="6">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingDateTime"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="2">
                                            <FormField
                                                isReadOnly
                                                name="firstLoadingZoneMoscow"
                                                text="firstLoadingZoneMoscowFormCaption"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="6">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingDateTime"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="2">
                                            <FormField
                                                isReadOnly
                                                name="lastUnloadingZoneMoscow"
                                                text="lastUnloadingZoneMoscowFormCaption"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="fields-bottom">
                                        <Grid.Column width="3">
                                            <FormField
                                                name="paymentTermDays"
                                                text="paymentTermDays"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="3">
                                            <FormField
                                                name="totalDeliveryCost"
                                                text="totalDeliveryCostWithoutVAT"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="4">
                                            <FormField
                                                name="totalDeliveryCostWithDiscount"
                                                text="totalDeliveryCostWithDiscount"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width="3">
                                            <FormField
                                                isReadOnly
                                                name="totalTime"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width="3">
                                            <FormField
                                                isReadOnly
                                                name="waybillNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                isReadOnly
                                                name="notes"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <FormField
                                                isReadOnly
                                                name="additionalInfo"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="tripCreatorName"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="tripCreatorPhoneNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width="8">
                                            <FormField
                                                isReadOnly
                                                name="tripCreatorEmail"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("dipsContacts")}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <FormField
                                                name="dispLogin"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="dispEmail"
                                                isTranslate
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <FormField
                                                name="dispTel"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="addDispEmail"
                                                isTranslate
                                                isReadOnly
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <DriverInnModal
                innRequired={form["isDriverInnRequired"]}
                open={openDriverInnModal}
                error={error}
                onClose={() => {
                    setOpenDriverInnModal(false);
                    dispatch(setCheckDriver({ checkInn: true }));
                }}
                driverId={
                    form["editableDriverId"] && form["editableDriverId"].value
                }
            />
            <DriverLicenseModal
                open={openDriverLicenseModal}
                error={error}
                onClose={() => {
                    setOpenDriverLicenseModal(false);
                    dispatch(setCheckDriver({ checkLicense: true }));
                }}
                driverId={
                    form["editableDriverId"] && form["editableDriverId"].value
                }
            />
            <ModelModal
                open={openVehicleModelModal}
                error={error}
                onClose={() => {
                    dispatch(setIsExistModel({ vehicles: true }));
                    openVehicleModelModal(false);
                }}
                id={form[vehicleId] && form[vehicleId].value}
                name="vehicles"
            />
            <ModelModal
                open={openTrailerModelModal}
                error={error}
                onClose={() => {
                    dispatch(setIsExistModel({ trailers: true }));
                    openTrailerModelModal(false);
                }}
                id={form[trailerId] && form[trailerId].value}
                name="trailers"
            />
            <RequiredFieldsModal
                open={openFieldsModal}
                id={form[vehicleId] && form[vehicleId].value}
                onClose={() => {
                    setOpenFieldsModal(false);
                    dispatch(setCheckRequiredFields(true));
                }}
                error={error}
                text={t("enteringEngineTypeAndFuelTypeText")}
                title={t("enteringEngineTypeAndFuelType")}
                fields={[
                    {
                        isRequired: true,
                        name: "engineType",
                        source: "engineType",
                        type: ENUM_TYPE,
                    },
                    {
                        isRequired: true,
                        name: "fuelType",
                        source: "fuelType",
                        type: ENUM_TYPE,
                    },
                ]}
                saveRequest={saveRequiredFields}
            />
        </Form>
    );
};

export default Information;
