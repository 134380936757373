import React, { useMemo } from "react";
import Field from "../../../../components/BaseComponents";

const FormField = (props) => {
    const {
        form = {},
        columnsConfig = {},
        error = {},
        settings = {},
        name,
        sourceParams,
    } = props;

    const sourceAdditionalParams = useMemo(() => {
        const params =
            columnsConfig[name] && columnsConfig[name].sourceAdditionalParams;
        let obj = {};
        if (params) {
            Object.keys(params).forEach((key) => {
                if (form[params[key]]) {
                    obj = {
                        ...obj,
                        [key]: form[params[key]],
                    };
                }
            });
        }
        return obj;
    }, [form, columnsConfig]);

    const isHidden = settings[name] && settings[name] === "hidden";

    return (
        <Field
            {...columnsConfig[name]}
            {...props}
            value={form[name]}
            checked={form[name]}
            sourceAdditionalParams={sourceAdditionalParams}
            error={error[name]}
            settings={settings[name]}
            sourceParams={sourceParams}
            isHidden={isHidden}
        />
    );
};

export default React.memo(FormField);
